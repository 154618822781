/**
 * Import React libraries.
 */
import React, {useEffect, useMemo, useState} from 'react';

/**
 * Import third-party libraries.
 */
import {Banner, Button, COLORS, LoadingIndicator, Size, SystemIcons,} from '@laerdal/life-react-components';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import CancelSubscriptionModal from '../modals/CancelSubscriptionModal';

/**
 * Import custom libraries.
 */
import {
  Organization,
  OrganizationService,
  PaymentType,
  Service,
  Subscription,
  SubscriptionStatus,
  SubscriptionUpdate,
  VALID_SUBSCRIPTION_STATUSES,
} from '../../../types';

/**
 * Import custom utils.
 */
import Api from '../../../utils/api';
import {dateOnlyMidnightUtc} from '../../../utils/functions';
import {ErrorToastOptions, SuccessToastOptions} from '../../../constants';
import {useNavigate} from 'react-router';
import {useToastContext, useUserContext} from '../../../userContext';
import {FormProvider, useForm} from 'react-hook-form';
import {BillingDetails, E_COURSES_FREE_PLAN, EditLicenseForm, SubscriptionDurations,} from './Common/types';
import {yupResolver} from '@hookform/resolvers/yup';
import {editLicenseSchema} from './Common/validations';
import {SubscriptionActionBlock} from "./Components/SubscriptionActionBlock";
import {GeneralDetailsGroup} from "./Components/EditForm/GeneralDetailsGroup";
import {SubscriptionOptionsGroup} from "./Components/EditForm/SubscriptionOptionsGroup";
import {BillingDetailsGroup} from "./Components/EditForm/BillingDetailsGroup";
import {LicenseInformationHelper} from "./Common/LicenseInformationHelper";
import DeactivateSubscriptionModal from "../modals/DeactivateSubscriptionModal";

/**
 * Add custom styling.
 */
const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.neutral_100};
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface OrgLicenseDetailsPage {
  organizationService: OrganizationService | undefined;
  organization: Organization | undefined;
  service: Service;
  subscription?: Subscription;
  setSubscription: (subscription: Subscription | undefined) => void;
}

const OrgLicenseDetailsPage = ({
                                 organizationService,
                                 organization,
                                 subscription,
                                 service,
                                 setSubscription
                               }: OrgLicenseDetailsPage) => {
  const {t} = useTranslation('OrganizationServices');
  const navigate = useNavigate();
  const {addToast} = useToastContext();
  const {selfServiceServices, countryConfigurations} = useUserContext();

  const [loading, setLoading] = useState<boolean>(false);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState<boolean>(false);
  const [isScheduleChangesModalVisible, setIsScheduleChangesModalVisible] = useState<boolean>(false);

  const useSalesforce =
    useMemo(() =>
        organization && countryConfigurations[organization?.address?.country?.codeAlpha2]?.useSalesforce &&
        organizationService && selfServiceServices?.includes(organizationService?.serviceId),
      [organization, countryConfigurations, organizationService, selfServiceServices]);

  const isPaddle = useMemo(() => !!subscription?.paddle, [subscription]);
  const isSalesforce = useMemo(() => !!subscription?.salesforce, [subscription]);

  const billingDetails = useMemo(() => (
    subscription?.billingDetails
      ? {
        billingOrganizationName: organization?.billingOrganizationName ?? '',
        billingContactPerson: `${subscription.billingDetails.firstName || ''} ${subscription.billingDetails.lastName || ''}`,
        billingEmailAddress: subscription.billingDetails.emailAddress ?? '',
        vatNumber: organization?.vatNumber ?? '',
      } as BillingDetails : {
        billingOrganizationName: organization?.billingOrganizationName ?? '',
        billingContactPerson: organization?.billingContactPerson ?? '',
        billingEmailAddress: organization?.billingEmailAddress ?? '',
        vatNumber: organization?.vatNumber ?? '',
      } as BillingDetails
  ), [organization, subscription]);

  const loaded = !!organization && !!organizationService && !!service;

  const form = useForm<EditLicenseForm>({
    resolver: yupResolver(editLicenseSchema),
    context: {
      useSalesforce: useSalesforce,
      serviceId: service?.id,
    },
  });
  const {handleSubmit, watch, reset, formState} = form;

  const plan = watch('plan');

  const resetForm = (subscription?: Subscription) => {
    if (!subscription) {
      if (service.id.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()) {
        reset({
          plan: E_COURSES_FREE_PLAN,
        });
      }
      return;
    }
    const plan = service.availablePlans?.find((p) => p.id === subscription.plan?.id);
    const tier = plan?.tiers?.find((t) => t.id === subscription.tier?.id);
    const startDate = new Date(subscription.startDate).getTime() > 0 ? subscription.startDate : (subscription.createdDate as Date);
    const expirationDate =
      !!subscription.expirationDate
        ? new Date(subscription.expirationDate)
        : undefined;

    reset({
      ...subscription,
      isTrial: subscription.status === SubscriptionStatus.Trial || subscription.status === SubscriptionStatus.DraftTrial,
      plan: plan,
      tier: tier,
      billingInterval: subscription.billingInterval,
      startDate: moment(startDate).toDate(),
      expirationDate: expirationDate,
      duration: SubscriptionDurations[4].value,
    });
  };

  useEffect(() => resetForm(subscription), [subscription]);

  const submitForm = (value: EditLicenseForm) => {
    // Submit the form
    setLoading(true);

    // Let's create subscription object
    const newSubscription: SubscriptionUpdate = {
      startDate: dateOnlyMidnightUtc(value.startDate)!,
      expirationDate: value.tier!.enableUnlimitedDuration ? undefined : dateOnlyMidnightUtc(value.expirationDate)!,
      poNumber: value.poNumber,

      autoRenew: value.autoRenew ? value.autoRenew : false,
      renewalPeriodInMonths: value.autoRenew ? value.renewalPeriodInMonths : 0,

      status:
        value.startDate > new Date()
          ? value.isTrial
            ? SubscriptionStatus.DraftTrial
            : SubscriptionStatus.Draft
          : value.isTrial
            ? SubscriptionStatus.Trial
            : SubscriptionStatus.Active,

      paymentType: subscription?.paymentType ?? PaymentType.Invoice,

      billingInterval: value.billingInterval,

      organizationServiceId: organizationService!.id,
      serviceId: service.id,
      planId: value.plan!.id,
      planTierId: value.tier!.id,
      isActive: true,
      meteorDomain: '',
    };

    // Set max subscription instances if tier allows it
    if (value.maxSubscriptionInstances && value.tier!.individualLicensing) {
      newSubscription.maxSubscriptionInstances = value.maxSubscriptionInstances;
    }

    // Let's make sure we show correctly trial banner
    if (!value.isTrial && subscription?.status == SubscriptionStatus.Trial) {
      value.trialExpirationDate = new Date();
    }

    if (subscription?.paddle || subscription?.salesforce) {
      Api.CreateSubscriptionScheduledAmendment(organization!.id, subscription?.id!, {
        amendmentDate: moment().startOf('day').add(1, 'day').utc().toDate(),
        planId: value?.plan?.id!,
        planTierId: value?.tier?.id!,
        autoRenew: !!value?.autoRenew,
        maxSubscriptionInstances: value?.maxSubscriptionInstances,
        billingInterval: value?.billingInterval!,
        duration: value?.renewalPeriodInMonths!,
        paymentType: subscription?.paymentType!

      })
        .then((amendment) => setSubscription({...subscription, amendment}))
        .then(() => addToast(t("Subscription amendment scheduled"), SuccessToastOptions))
        .catch(() => addToast(t("There was a problem scheduling an amendment"), ErrorToastOptions))
        .finally(() => setLoading(false));
    } else if (!subscription) {
      Api.CreateSubscription(organization?.id!, newSubscription)
        .then((data) => {
          setSubscription(data);

          addToast(t('Organization service subscription created.'), SuccessToastOptions);
        })
        .catch(() => {
          addToast(t('Organization service subscription create failed.'), ErrorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Api.UpdateSubscription(organization?.id!, subscription!.id, newSubscription)
        .then((result) => {
          setSubscription(result);

          addToast(t('Organization service subscription updated.'), SuccessToastOptions);
        })
        .catch(() => {
          addToast(t('Organization service subscription update failed.'), ErrorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deleteSubscription = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    if (subscription?.id && organization?.id) {
      setLoading(true);

      // Let's delete the subscription
      Api.DeleteSubscription(organization?.id, subscription.id)
        .then(() => {
          addToast(t('Organization subscription deleted successfully.'), SuccessToastOptions);
          setSubscription(undefined);
        })
        .catch(() => {
          addToast(t('There was an error. Subscription not deleted.'), ErrorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const showForm = subscription && subscription?.status != SubscriptionStatus.Deactivated
    || process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() == service.id.toLowerCase();

  const disableSave = !formState.isDirty;

  const willUpgrade = LicenseInformationHelper.WillUpgrade(subscription?.amendment);
  const willDowngrade = LicenseInformationHelper.WillDowngrade(subscription?.amendment);
  const willChangeTerm = LicenseInformationHelper.WillChangeTerm(subscription?.amendment);

  const upgradeDowngradeText = LicenseInformationHelper.FormatUpgradeDowngradeText(t, service, subscription?.amendment);
  const termChangeText = LicenseInformationHelper.FormatTermChangeText(t, service, subscription?.amendment);
  const amendmentDate = moment(subscription?.amendment?.amendmentDate).format('LL');

  return (
    <>
      <DeactivateSubscriptionModal
        visible={isDeactivateModalVisible}
        onClose={() => setIsDeactivateModalVisible(false)}
        onSubmitted={() => {
          navigate(`/organization/${organization?.id}/license-management/`);
        }}
        subscription={subscription}
        organization={organization}
      />

      <CancelSubscriptionModal
        visible={isCancelModalVisible}
        onClose={() => setIsCancelModalVisible(false)}
        onSubmitted={() => {
          navigate(`/organization/${organization?.id}/license-management/`);
        }}
        subscription={subscription}
        organization={organization}
      />

      {!loaded && <LoadingIndicator/>}
      {loaded && (
        <FormProvider {...form}>
          <StyledForm onSubmit={plan == E_COURSES_FREE_PLAN ? deleteSubscription : handleSubmit(submitForm)}>
            {
              subscription?.status === SubscriptionStatus.Canceled &&
              <Banner size={Size.Small} type="warning">
                {t('Subscription cancelled on {{date}}', {date: moment(subscription?.cancellationDate).format('LL')})}
              </Banner>
            }
            {
              subscription?.status === SubscriptionStatus.Deactivated &&
              <Banner
                size={Size.Small}
                type="neutral"
                icon={<SystemIcons.Information/>}
                linkText={t('Create new subscription')}
                link={`#`}
                linkAction={() =>
                  navigate(`/organization/${organization?.id}/license-management/new/${service.id}?subscriptionId=${subscription?.id}&orgServiceId=${organizationService?.id}`)
                }>
                {t('Subscription deactivated on {{date}}.', {date: moment(subscription?.deactivationDate).format('LL')})}
                <br/>
                {t('To restore access')}
              </Banner>
            }

            {
              subscription?.status === SubscriptionStatus.Expired &&
              <Banner
                size={Size.Small}
                type="neutral"
                icon={<SystemIcons.Information/>}
                linkText={t('Create new subscription')}
                link={`#`}
                linkAction={() =>
                  navigate(`/organization/${organization?.id}/license-management/new/${service.id}?subscriptionId=${subscription?.id}&orgServiceId=${organizationService?.id}`)
                }>
                {t('Subscription expired on {{date}}.', {date: moment(subscription?.actualExpiredDate).format('LL')})}
                <br/>
                {t('To restore access')}
              </Banner>
            }


            {
              willDowngrade &&
              <Banner
                size={Size.Small}
                type="neutral"
                icon={<SystemIcons.Information/>}>
                This subscription will downgrade to {upgradeDowngradeText} on {amendmentDate}.
              </Banner>
            }

            {
              willUpgrade &&
              <Banner
                size={Size.Small}
                type="neutral"
                icon={<SystemIcons.Information/>}>
                This subscription will upgrade to {upgradeDowngradeText} on {amendmentDate}.
              </Banner>
            }


            {
              willChangeTerm &&
              <Banner
                size={Size.Small}
                type="neutral"
                icon={<SystemIcons.Information/>}>
                This subscription will change term to {termChangeText} on {amendmentDate}.
              </Banner>
            }

            {
              isSalesforce &&
              <SubscriptionActionBlock title={t('This subscription is routed through Salesforce')}
                                       description={t('Any changes made to this subscription will take effect from tomorrow.')}/>
            }

            <GeneralDetailsGroup service={service}
                                 subscription={subscription}
                                 organization={organization!}
                                 organizationService={organizationService!}/>
            {
              showForm &&
              <SubscriptionOptionsGroup service={service}
                                        subscription={subscription!}
                                        useSalesforce={!!useSalesforce}/>
            }
            {
              showForm &&
              <BillingDetailsGroup billingDetails={billingDetails!}
                                   subscription={subscription!}
                                   useSalesforce={!!useSalesforce}/>
            }

            {
              subscription &&
              VALID_SUBSCRIPTION_STATUSES.includes(subscription?.status) &&
              <SubscriptionActionBlock title={t('Cancel subscription')}
                                       description={
                                         <>
                                           If you cancel this subscription, then the customer will still have access
                                           until the end of the current subscription term.
                                         </>
                                       }
                                       banner={
                                         isSalesforce
                                           ? <Banner type={'neutral'}
                                                     size={Size.Small}
                                                     icon={<SystemIcons.Information/>}>
                                             <strong>This subscription is routed through Salesforce</strong>
                                             <br/>
                                             Changes made will take effect from tomorrow.
                                           </Banner>
                                           : undefined
                                       }
                                       action={<>
                                         {
                                           LicenseInformationHelper.CanCancel(subscription) &&
                                           <Button variant={'critical'}
                                                   disabled={!LicenseInformationHelper.CanAmend(subscription)}
                                                   width={'max-content'}
                                                   onClick={() => setIsCancelModalVisible(true)}>
                                             {t('Cancel subscription')}
                                           </Button>
                                         }
                                         <Button variant={'critical'}
                                                 disabled={!LicenseInformationHelper.CanAmend(subscription)}
                                                 width={'max-content'}
                                                 onClick={() => setIsDeactivateModalVisible(true)}>
                                           {t('Terminate access')}
                                         </Button>
                                       </>
                                       }
              />
            }

            <SeparatorLine/>

            {/* Render Action Buttons */}
            <ActionsContainer>
              <Button
                id="cancelBtn"
                variant="tertiary"
                size={Size.Large}
                onClick={() => {
                  resetForm();
                  navigate(`/organization/${organization?.id}/license-management/`);
                }}>
                {t('Cancel')}
              </Button>
              <Button id="saveChangesBtn"
                      type="submit"
                      size={Size.Large}
                      loading={loading}
                      disabled={disableSave}>
                {t('Save changes')}
              </Button>
            </ActionsContainer>
          </StyledForm>
        </FormProvider>
      )}
    </>
  );
};

export default OrgLicenseDetailsPage;